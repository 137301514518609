/* Main Card */
.cta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 2rem;
    margin: 4rem;
    border-radius: 1rem;
    background: var(--gradient-bar)
}
/* Content Container */
.cta-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #fff;
}
/* Top Subtitle */
.cta-content > p {
    font-family: var(--font-family);
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
}
 /* Main Header */
.cta-content > h3 {
    font-family: var(--font-family);
    font-size: 24px;
    line-height: 45px;
    font-weight: 800;
}
/* Button */
.cta-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2rem;
}

.cta-btn > button {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
    padding: 0.3rem 0.5rem;
    border: none;
    border-radius: 2rem;
    min-width: 150px;
}
.cta-btn > button > p {
    color: black;
    font-size: 20px;
}
.cta-btn > button:hover {
    background-color: rgb(188, 209, 221);
    border: 1px solid black;
    cursor: pointer;
}

/* Media Query */
@media screen and (max-width: 650px) {
    .cta {
        flex-direction: column;
        margin: 4rem 0rem;
    }
    .cta-content > h3 {
        font-size: 18px;
        line-height: 32px;
    }
    .cta-btn {
        margin: 2rem 0 0;
        display: flex;
        width: 100%; 
    }
    .cta-btn > button {
        font-size: 14px;
        line-height: 28px;
    }
}

@media screen and (max-width: 280px) {
    .cta {
        display: flex;
        width: 100%;  
    }
}