.banner {
    display: flex;
    height: 100%;
    width: 100%;
    z-index: 999;

}
.banner__container {
    top: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    z-index: 999;
}
.banner__container > .banner {
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
}
.banner > img  {
    object-fit: contain;
    width: 100%;
    height: 100%;
    display: flex;
}

.banner__container > .banner > img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    -o-object-fit: cover;
    object-fit: cover;
    opacity: .9;
    
}


.banner__content {
    z-index: 3;
    color: #000;
    position: absolute;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    font-family: var(--font-family);
    width: 65%;
    line-height: 70px;
    z-index: 999;
    margin-top: 5%;
    padding: 10rem 0rem 1rem 5rem;
}
/* Hero Paragraph */
.banner__content > p {
    color: white;
    font-size: 20px;
    width: 80%;
    align-items: center;
    font-weight: 500;
    
}
.banner__content > h2 {
    color: rgb(150, 149, 149);
    font-size: 30px;
    width: 100%;
    align-items: center;
    font-weight: 900;
    margin-top: 5%;
    margin-bottom: 5%;
}
/* Main Header */
.banner__content > h1 {
    color: #fff;
    font-weight: 1000;
    width: 100%;
    font-size: 50px;
}
.banner__content > h3 {
    flex-wrap: wrap;
    align-items: center;
    font-size: 40px;
    font-weight: 100;
}
.banner__content > h4 {
    font-size: 60px;
    align-items: center;
    font-weight: 300;
    color: rgb(255, 255, 255);
    
}

.banner__btn {
    flex-direction: row;
    display: flex;
    margin: 1 auto;
    justify-content: space-between;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    color: white;
    outline: none;
    border: none;
   

 }
 .banner__content > a {
     
     color: white;
     border: 1px solid white;
     outline: none;
     font-weight: 500;
     width: 50%;
 }
 .banner__btn:hover {
    background-color: white;
    color: black;
 }
 .banner__content > a:hover{
    background-color: white;
     color: white;
     cursor: pointer;
     border: none;
     background-color: transparent;
    cursor: pointer;
    border: 2px solid white;
 }

 @media screen and (max-width: 1248px) {
    .banner__content {
        z-index: 3;
        color: #000;
        position: absolute;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        font-family: var(--font-family);
        width: 100%;
        line-height: 40px;
        z-index: 999;
        margin-top: -3%;
        padding: 10rem 10rem 2rem 8rem;
    }

    .banner__btn {
        flex-direction: row;
        display: flex;
        margin: 1 auto;
        justify-content: space-between;
        width: 100%;
        text-align: center;
        align-items: center;
        justify-content: center;
     }
     .banner__content > a{
        background-color: transparent;
         outline: none;
         text-decoration: none;
         color: black;
         font-size: 20px;
         font-weight: 500;
         width: 50%;
         border: 2px solid white;
     }
     .banner__content > a:hover{
        background-color: transparent;
         color: white;
         cursor: pointer;
         border: 2px solid white;
     }
    
    
 }
 @media screen and (max-width: 920px) {
    .banner__content {
        z-index: 3;
        color: #000;
        position: absolute;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        font-family: var(--font-family);
        width: 100%;
        line-height: 60px;
        z-index: 999;
        margin-top: 11%;
        padding: 1rem 10rem 1rem 5rem;
    }
    /* Hero Paragraph */
    .banner__content > p {
        color: white;
        font-size: 18px;
        width: 100%;
        line-height: 50px;
        align-items: center;
        font-weight: 500;
    }
    /* Main Header */
    .banner__content > h1 {
        font-size: 30px;
    }
 }

 @media screen and (max-width: 760px) {
    .banner__content {
        z-index: 3;
        position: absolute;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        font-family: var(--font-family);
        width: 100%;
        line-height: 50px;
        z-index: 999;
        margin-top: 15%;
        padding: 1rem;
    }
    /* Hero Paragraph */
    .banner__content > p {
        color: white;
        font-size: 20px;
        width: 100%;
        line-height: 30px;
        align-items: center;
        font-weight: 300;
    }
    /* Main Header */
    .banner__content > h1 {
        font-size: 30px;
        width: 100%;
    }
    .banner__content > a {
        padding: 0rem;
        font-size: 20px;
        margin-top: -10%;
        width: 20%;
    }
    .banner__content > h2 {
        font-size: 15px;
        margin-top: 0%;
        padding-bottom: 8%;
    }
 }

 @media screen and (max-width: 590px) {
    .banner__content {
        z-index: 3;
        position: absolute;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        font-family: var(--font-family);
        width: 100%;
        line-height: 30px;
        z-index: 999;
        margin-top: 15%;
    }
    /* Hero Paragraph */
    .banner__content > p {
        color: white;
        font-size: 10px;
        width: 100%;
        align-items: center;
        line-height: 20px;
        font-weight: 300;
    }
    /* Main Header */
    .banner__content > h1 {
        font-size: small;
        width: 100%;
    }
    .banner__content > a {
        font-size: small;
        width: 30%;
    }
    .banner__content > h2 {
        font-size: 10px;
        margin-top: 0%;
        padding-bottom: 10%;
    }
 }
 @media screen and (max-width: 390px) {
    .banner__content {
        z-index: 3;
        position: absolute;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        font-family: var(--font-family);
        width: 100%;
        z-index: 999;
        margin-top:17%;
    }
    /* Hero Paragraph */
    .banner__content > p {
        color: white;
        font-size: 8px;
        width: 100%;
        align-items: center;
        line-height: 10px;
        font-weight: 300;
    }
    /* Main Header */
    .banner__content > h1 {
        font-size: xx-small;
        width: 100%;
    }
    .banner__content > a {
        font-size: small;
        line-height: 15px;
        width: 100%;
        margin-top: -6%;
    }
    .banner__content > h2 {
        font-size: 10px;
        margin-top: -2%;
        padding-bottom: 5%;
    }
 }
