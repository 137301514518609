.action__container {
    display: flex;
    flex-direction: row;
    width: 100%;
}
.action__image > img {
    width: 100%;
    object-fit: contain;
    display: flex;
    margin-top: 1%;
}
.action__text > h1 {
    font-size: 30px;
    width: 100%;
    font-weight: 400;
    color: #919394;
}
.action__text > h2 {
    font-size: 16px;
    width: 100%;
    font-weight: 400;
    color: #919394;
}
.action__text {
    color: white;
    font-family: var(--font-family);
    align-items: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 10%;
    line-height: 45px;
}
.action__btns {
   flex-direction: row;
   display: flex;
   margin: 1 auto;
   justify-content: space-between;
   width: 100%;
   margin-top: 10%;
}
.action__btns > a >  button{
    background: var(--gradient-bar2);
    border: none;
    outline: none;
    text-decoration: none;
    color: white;
    font-size: larger;
    padding: 3.5%;
    border-radius: 20px;
}
@media screen and (max-width: 900px) {
    .action {
        width: 100%;
        flex-direction: column;
        display: flex;
    }
    .action__container {
        flex-direction: column;
    }
    .action__btns {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    .action__btns > a > button {
        padding: 4%;
        width: 100%;
        margin: 1rem;
        justify-content: center;
        align-items: center;
        display: flex;
    }
    .action__text > p {
        font-size: 16px;
        width: 100%;
    }
}