.info {
    display: flex;
    width: 100%;
    background: black;
    height: fit-content;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.info__container {
    display: flex;
    margin: auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.info__text {
    display: flex;
    padding: 6%;
    flex-direction: column;
    line-height: 500%;
    color: white;
    font-family: var(--font-family);
}
.info__text > h1 {
    color: #25a2dc;
    font-weight: 600;
    flex-wrap: wrap;
    align-items: center;
    font-size: 50px;
}
.info__text > h2 {
    align-items: center;
    font-size: 25px;
    font-weight: 500;
}
.info__text > h3 {
    flex-wrap: wrap;
    align-items: center;
    font-size: 30px;
    font-weight: 200;
}
.info__text > h4 {
    font-size: 20px;
    font-weight:700;
    color: rgb(165, 162, 162);
}
.info__text > p {
    color: rgb(255, 255, 255);
    font-size: 23px;
    align-items: center;
    font-weight: 600;
}
.info__image {
    display: flex;
    object-fit: contain;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.info__image-left {
    display: flex;
    object-fit: contain;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.info__image > img {
    display: flex;
    object-fit: contain;
    width: 900px;
    height: 100%;
}
.gradient__button {
    display: flex;
    background: var(--gradient-bar2);
    padding: 15px;
    color: white;
    font-size: 20px;
    width: 100%;
    text-align: center;
    justify-content: center;
    border-radius: 20px;
    border: none;
}
.gradient__button:hover, .gradient__button:active {
    display: flex;
    background: var(--gradient-bar-hover);
    cursor: pointer;
    animation-duration: 300ms;
    animation: infinite;
}
@media screen and (max-width: 1478px) {
    .info__image > img {
        display: flex;
        object-fit: contain;
        width: 650px;
        height: 100%;
    }
}
@media screen and (max-width: 1189px) {
    .info {
        flex-direction: column;
       
    }
    .info__container {
        flex-direction: column;
        width: 100%;
        padding-top: 10%;
    }
    .info__image {
        display: flex;
        object-fit: contain;
        width: 100%;
        height: 100%;
        align-items: flex-end;
        justify-content: flex-end;
    }
    .info__image-left {
        display: flex;
        object-fit: contain;
        width: 100%;
        height: 100%;
        align-items: flex-start;
        justify-content: flex-start;
    }
}
@media screen and (max-width: 768px) {
    .info {
        flex-direction: column;
    }
    .info__container {
        flex-direction: column;
    }
    .info__container {
        flex-direction: column;
        width: 100%;
        padding-top: 20%;
    }
    .info__image {
        display: flex;
        object-fit: contain;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
    .info__image {
        display: flex;
        object-fit: contain;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }
    .info__image-left {
        display: flex;
        object-fit: contain;
        width: 100%;
        height: 100%;
        align-items: flex-start;
        justify-content: flex-start;
    }
}
@media screen and (max-width: 700px) {
    .info {
        width: 100%;
        
    }
    .info__image > img {
        display: flex;
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
    .info__text {
        width: 100%;
    }
    .info__text > h1 {
        font-weight: 600;
        flex-wrap: wrap;
        align-items: center;
        font-size: xx-large;
        width: 100%;
    }
    .info__text > h2 {
        align-items: center;
        font-size: 15px;
        line-height: 25px;
        font-weight: 400;
    }
    .info__text > h3 {
        font-size: 18px;
        font-weight: 200;
    }
    .info__text > h4 {
        font-size: 20px;
    }
    .info__text > p {
        font-size: 20px;
    }
}
@media screen and (max-width: 260px) {
    .info__container {
        text-align: center;
        padding-top: 25%;
    }
    .info__text > h1 {
        font-size: x-large;
    }
}