.preorder {
    align-items: center;
    display: flex;
    padding: 15rem;
    flex-direction: column;
    text-align: center;
    background: black;
    color: white;
}

/* Email Input form */
.preorder-form {
    width: 100%;
    margin: 2rem 0 1rem;
    flex-direction: row;
}

.preorder-form > input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: white;
    border: 0.1px solid rgba(255, 255, 255, 0.133);
    padding: 0 1rem;
    outline: none;
    color: #000;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}


.display__title {
    width: 100%;
}
.super__title {
    font-size: 30px;
    font-weight: 200;

}
.display__title > h1 {
    font-size: 100px;
    font-weight: 700;
    width: 100%;
    text-align: center;
}
.display__title > p {
    color: gray;
}
@media screen and (max-width: 768px) {
    .preorder {
        flex-direction: column;
        display: flex;
        width: 100%;
    }
    .display__title > h1 {
        font-size: xxx-large;
    }
    .header__image > img {
        object-fit: contain;
        width: 200px;
        display: flex;
    }

    
}
@media screen and (max-width: 668px) {
    .preorder {
        flex-direction: column;
        display: flex;
        object-fit: contain;
        width: 100%;
        padding: 2rem;
       
    }
    .display__title {
        margin-top: 20%;
    }
    .display__title > h1 {
        font-size: 50px;
        width: 100%;
    }
    .display__title > h2 {
        font-size: 10px;
        width: 100%;
    }
    .header__image > img {
        object-fit: contain;
        width: 200px;
        display: flex;
    }

    
}
@media screen and (max-width: 368px) {
    .preorder {
        flex-direction: column;
        display: flex;
        object-fit: contain;
        width: 100%;
        padding: 2rem;
       
    }
    .display__title {
        margin-top: 30%;
    }
    .display__title > h1 {
        font-size: 30px;
        width: 100%;
    }
    .display__title > h2 {
        font-size: 10px;
        width: 100%;
    }
    .header__image > img {
        object-fit: contain;
        width: 200px;
        display: flex;
    }
    .small__text {
        font-size: 10px;
    }

    
}