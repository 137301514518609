.navbar {
    display: flex;
    background: transparent;
    height: 120px;
    width: 100%;
    z-index: 9999;
    left: 0;
    top: 0;
    right: 0; 
    position:absolute;
    margin: 0;
    width: 100%;
    border: none;
    border-color: transparent;
}

/* Logo */
.navbar__logo {
    display: block;
    background-position: center;
    margin: 0 auto;
}
.navbar__logo > a > img {
    width: 310px;
    display: flex;
}

.navbar__icon {
    display: none;
}

/* Link Container */
.navbar__links {
    width: 1000px;
    margin: 0 auto;
    margin-top: 1.2rem;
    padding: 0 8px;
    font-family: var(--font-family);
}

.navbar__links-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    margin-bottom: 0px;
}
.nav__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    list-style: none;
    margin-bottom: 0px;
    width: 1000px;
    margin: 0 auto;
    padding: 0 8px;
}
.navbar__links-container > .nav__container > p > a{
    color: #fff;
    text-decoration: none;
    font-family: var(--font-family);
    outline: none;
    font-size: 18px;
    opacity: 1;
    transition: opacity 800ms;
    margin: 1.9rem;
    display: flex;
    text-decoration: none;
}


.nav__container > p:hover {
    opacity: 1;
}

/* Icons */
.navbar__menu {
    display: flex;
    background-position: center;
    height: 44px;
    margin-left: -1rem;
    width: 15px;
    background-repeat: no-repeat;
    z-index: 0;
    
}
.account__menu {
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    height: 100%;
    
}
.account__menu > svg:hover{
    cursor: pointer;
}



/* Icon Divider */
.icon__div {
    width: 30px
}

/* Button Hover */
.navbar__sign > button:hover {
    background: #0095ff;
    border-radius: 15px;
    outline: none;
    cursor: pointer;
}
.navbar__sign > button > p {
    color: black;
    font-size: 14px;
    margin: 0.5rem;
}


/* Menu */
.navbar__menu {
    justify-content: flex-start;
    display: none;
}
.menu > button:hover {
    background: white;
    border-radius: 15px;
    outline: none;
    cursor: pointer;
}


/* Menu Icon */
.navbar__menu > svg {
    cursor: pointer;
}
/* Menu Container */
.nav-menu__container {
    display: flex;
    background-color: rgb(0, 0, 0);
    padding: 2rem;
    position: fixed;
    margin-left: -2rem;
    top: 0px;
    overflow: hidden;
    height: 100%;
    animation: 5s infinite;
    transition: .2s;
    width: 100%;
}
.navbar__menu > :first-child {
    z-index: 1000;
    align-items: center;
    display: flex;
    justify-content: center;
    left: 0;
    border-radius: 50px;
    right: 0;
}
.navbar__menu > :first-child:hover{
    color: #0095ff;
    cursor: pointer;
}

.nav-menu__container > p {
    margin: 1rem 0;
}
.menu__links {
    width: 100%;
}
/* Styling for the Menu Text */
.menu__links > nav > p > a {
    display: flex;
    color: #fff;
    text-decoration: none;
    outline: none;
    font-size: 40px;
    font-weight: 600;
    opacity: 1;
    line-height: 55px;
    transition: opacity 800ms;
    margin-bottom: 0px;
    display: flex;
    text-decoration: none;
    width: 100%;
    margin: 5rem;
}
.menu__links > nav > a{
    color: #fff;
    text-decoration: none;
}
.menu__links > nav > a:hover{
    color: #23aeeb;
    text-decoration: none;
    cursor: pointer;
    
}

/* Hover Styling for the Menu Text */
.menu__links > nav > p > a:hover{
    display: flex;
    color: #fff;
    padding: 20px;
    line-height: 0px;
    background: #575757;
    width: fit-content;
    border-radius: 10px;
    width: max-content;
    animation: forwards 200ms;
}
.menu__links > nav > img{
    display: flex;
    object-fit: contain;
    width: 100%;
}
.menu__links > nav > img:hover{
    border: 1px solid #0095ff;
    background: #0095ff;
    cursor: pointer;
}
/* Menu */
.navbar__menu {
    justify-content: flex-start;
    display: none;
    transition-property: transform, top;
    transition-delay: 3ms, 4ms;
    transition-duration: 900ms;
}
.menu > button:hover {
    background: white;
    border-radius: 15px;
    width: 100%;
    outline: none;
    cursor: pointer;
}

/* Menu Icon */
.navbar__menu > svg {
    cursor: pointer;
}
.account__menu {
    height: 100%;
    display: block;
    background-position: center;
    margin: 0 auto;
    right: 0; 
}
.account__menu > svg{
    height: 100%;
    display: block;
    background-position: center;
    margin: 0 auto;
    right: 0; 
}
.account-menu__container {
    display: flex;
    flex-direction: column;
    text-align: center;
    background: rgb(3, 3, 7);
    font-family: var(--font-family);
   
}
.account__links {
    margin-top: 10%;
    margin-bottom: 50%;
}

.account__links > a {
    display: block;
    align-items: center;
    justify-content: center;
    color: #fff !important;
}
.account__links a :hover {
    color: #23aeeb !important;
}


/* Media Query */
@media screen and (max-width: 1390px) {
    .navbar {
        width: 100%;
        padding: 0 30px;
    }
    .nav-menu__container {
        width: 110%;
    }
    .menu__links > nav > p > a{
        font-size: large;
    }
    .menu__links > nav > img{
        width: 95%;
        margin: 1rem;
    }
    .menu__links > nav > a > h1{
        font-size: large;
        font-weight: 800;
        width: 95%;
        padding: 0.8rem;
    }
    .navbar__logo {
        display: none;
    }
    .navbar__icon {
        display: flex;
        object-fit: contain;
        
        margin: 5px 20px 15px 45%;
    }
    .navbar__icon > a > img { 
        width: 48px;
        display: flex;
    }
    .navbar__links-container {
        display: none;
    }
    .navbar__menu > svg {
        display: flex;
    }
    .navbar__menu {
        left: 0;
        color: #fff;
        position: absolute;
        width: 48px;
        z-index: 10;
        display: flex;
        margin: -5.5rem 2rem 2rem 1rem;
        opacity: 1;
    }
    
}
@media screen and (max-width: 765px) {
    .navbar__menu {
        left: 0;
        color: #fff;
        position: absolute;
        width: 48px;
        z-index: 10;
        display: flex;
        margin: -5.5rem 0 2rem 1rem;
        opacity: 1;
    }
}



@media screen and (max-width: 300px) {

    .menu__links > nav > p > a {
        font-size: medium;
        margin: 1rem;
    }
    .menu__links > nav > img{
        margin-right: 1rem;
        width: 55.5%;
    }
    .navbar__icon {
        margin: 0px 0px 200px 37%;
        margin-top: -1%;
    }
    .navbar__menu {
        margin: -15.9rem 0 2rem 1rem;
        opacity: 1;
    }
    
}