.hiw {
    display: flex;
    width: 100%;
    flex-direction: column;
}
.hiw__text {
    color: white;
    font-family: var(--font-family);
    justify-content: center;
    text-align: center;
    padding: 4rem;
    line-height: 100px;
}
.hiw__text > h1 {
    font-weight: 500;
    font-size: 50px;
}
.hiw__text > p {
    font-weight: 300;
    line-height: 30px;
}
.hiw__text > h1 {
    font-weight: 500;
}
.hiw__image > img {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 900px) {
    .hiw {
        display: flex;
        width: 100%;
        flex-direction: column;
    } 
    .hiw__text {
        width: 100%;
    }
    .hiw__text > h1{
        width: 100%;
        font-size: xx-large;
    }
    .hiw__text > p{
        width: 100%;
        font-size: large;
        padding: -5rem;
        text-align: center;
    }
}