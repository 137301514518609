.left {
    display: flex;
    width: 100%;
    background: black;
    height: fit-content;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.left__container {
    display: flex;
    margin: auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.left__text {
    display: flex;
    padding: 6%;
    flex-direction: column;
    line-height: 600%;
    color: white;
    font-family: var(--font-family);
}
.left__text > h1 {
    color: #fff;
    font-weight: 600;
    flex-wrap: wrap;
    align-items: center;
    font-size: 50px;
}
.left__text > h2 {
    align-items: center;
    font-size: 20px;
    font-weight: 900;
    line-height: 40px;
    color: rgb(255, 247, 247);

}
.left__text > h3 {
    flex-wrap: wrap;
    align-items: center;
    font-size: 30px;
    font-weight: 200;
   
}
.left__text > h4 {
    font-size: 20px;
    font-weight:700;
    color: rgb(165, 162, 162);
}
.left__text > p {
    color: rgb(177, 174, 174);
    font-size: 20px;
    align-items: center;
    font-weight: 600;
    line-height: 60px;
}

.left__image {
    display: flex;
    object-fit: contain;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}

.left__image > img {
    display: flex;
    object-fit: contain;
    width: 900px;
    height: 100%;
}
.footer__buttons, .footer-links_div {
    font-family: var(--font-family);
}

@media screen and (max-width: 1478px) {
    .left__image > img {
        display: flex;
        object-fit: contain;
        width: 650px;
        height: 100%;
    }
}
@media screen and (max-width: 1168px) {
    .left {
        flex-direction: column;
    }
    .left__container {
        flex-direction: column;
    }
    .left__image {
        display: flex;
        object-fit: contain;
        width: 100%;
        height: 100%;
        align-items: flex-end;
        justify-content: flex-end;
    }

}
@media screen and (max-width: 768px) {
    .left {
        flex-direction: column;
    }
    .left__container {
        flex-direction: column;
    }
    .left__image {
        display: flex;
        object-fit: contain;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }


}
@media screen and (max-width: 400px) {
    .left__image > img {
        display: flex;
        object-fit: contain;
        width: 250px;
        height: 100%;
    }

    .left__text {
        width: 100%;
    }
    .left__text > h1 {
        font-weight: 600;
        flex-wrap: wrap;
        align-items: center;
        font-size: 30px;
    }
    .left__text > h2 {
        align-items: center;
        font-size: 25px;
        line-height: 170%;
        font-weight: 200;
        margin-top: 20%;
    }
    .left__text > h3 {
        font-size: 18px;
        font-weight: 200;
    }
    .left__text > h4 {
        font-size: 20px;
    }
    .left__text > p {
        font-size: 20px;
        line-height: 170%;
        font-weight: 200;
    }
}