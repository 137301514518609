
.hero {
    display: flex;
    height: 100%;

}
.hero__container {
    top: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 99;
    position: relative;
}
.hero__container > .hero {
    top: 0;
    right: 0;
    left: 0;
    width: 95%;
    height: 100%;
    
}
.hero > video > source {
    object-fit: contain;
    width: 100%;
    height: 100%;
    display: flex;
}

.hero__container > .hero > video {
    width: 100%;
    height: 100%;
    overflow: hidden;
    -o-object-fit: cover;
    object-fit: cover;
    opacity: .5;
    
}


.hero__content {
    z-index: 3;
    color: #fff;
    position: absolute;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 50px;
    align-items: flex-start;
    font-family: var(--font-family);
    width: 75%;
    padding: 5rem;
    line-height: 160px;
}
/* Hero Paragraph */
.hero__content > p {
    color: #d8d6d6;
    font-size: 50px;
    width: 60%;
    align-items: center;
    font-weight: 300;
}
/* Main Header */
.hero__content > h1 {
    color: #00aeff;
    font-weight: 300;
    flex-wrap: wrap;
    align-items: center;
    font-size: 105px;
}
.hero__content > h2 {
    align-items: center;
    font-size: 60px;
    font-weight: 800;
}
.hero__content > h3 {
    flex-wrap: wrap;
    align-items: center;
    font-size: 40px;
    font-weight: 100;
}
.hero__content > h4 {
    font-size: 60px;
    align-items: center;
    font-weight: 300;
    color: rgb(255, 255, 255);
    
}

/* Email Input form */
.header-form {
    width: 100%;
    margin: 2rem 0 1rem;
    flex-direction: row;
}

.header-form > input {
    flex: 2;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    background: transparent;
    border: 0.1px solid rgba(255, 255, 255, 0.133);
    padding: 0 1rem;
    outline: none;
    color: #fff;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}


form > .header-btn {
    flex: 0.6;
    margin-bottom: 0%;
    width: 100%;
    font-family: var(--font-family);
    font-weight: 400;
    padding: 0 1rem;
    color: #fff;
    cursor: pointer;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.wl-su_btn {
    border-radius: 10px;
    background: transparent;
    color: white;
    font-weight: 600;
    padding: 1rem;
    margin-top: 1.5%;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: none;
}
.wl-su_btn:hover {
    cursor: pointer;
    color: #00aeff;
}



/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (max-width: 3529px) {
    .hero__container {
        width: 100%;
        margin-bottom: 0%;
        height: 100%;
    }
    .hero__content {
        line-height: 100px;
    }
    .hero__container > .hero {
        width: auto;
    }

    .hero__container > .hero > .hero__content {
        margin-right: 8%;
        
    }
    /* Main Header */
    .hero__content > h1 {
        font-size: 85px;
    }
    .hero__content > h2 {
        font-size: 50px;
    }
    .hero__content > h3 {
        font-size: 40px;
    }
    .hero__content > h4 {
        font-size: 40px;
    }
    .hero__content > p {
        font-size: 40px;
        width: 100%;
    }
}
@media only screen and (max-width: 1981px) {
    .hero__content {
        margin: 20% 2rem 5rem;
        line-height: 35px;
        width: 100%;
    }
    .hero__content > p {
        font-size: 17px;
        line-height: 30px;
        width: 100%;
    }
    .hero__content > h1 {
        font-size: 50px;
        font-weight: 500;
    }
    .hero__content > h2 {
        font-size: 18px;
    }
    .hero__content > h3 {
        font-size: 15px;
    }
    .hero__content > h4 {
        font-size: 15px;
        
    }
}
@media only screen and (max-width: 995px) {
    .hero {
        padding-bottom: 5%;
        
    }
    .hero__container {
        width: 100%;
        margin-bottom: 0%;
        height: 700px;
        
    }
    .hero__content {
        margin: 16% -2rem 1rem;
        line-height: 50px;
        width: 100%;
    }
    .hero__content > p {
        font-size: 19px;
        line-height: 30px;
    }
    .hero__content > h1 {
        font-size: 50px;
    }
    .hero__content > h2 {
        font-size: 18px;
    }
    .hero__content > h3 {
        font-size: 16px;
    }
    .hero__content > h4 {
        font-size: 17px;
        
    }
}
@media only screen and (max-width: 481px) {
    .hero__container {
        width: 100%;
        margin-bottom: 0%;
        height: 600px;
    }
    .hero__content {
        margin: 20% 1rem 0rem;
        padding: 1rem;
        line-height: 0px;
        width: 100%;
    }
    .hero__content > p {
        font-size: 15px;
        line-height: 20px;
    }
    .hero__content > h1 {
        font-size: 30px;
    }
    .hero__content > h2 {
        font-size: 18px;
        font-weight: 300;
        
    }
    .hero__content > h3 {
        font-size: 10px;
    }
    .hero__content > h4 {
        font-size: 16px;
        font-weight: 800;
    }
    form > .header-btn {
        flex: 0.6;
        margin-bottom: 0%;
        width: 100%;
        font-family: var(--font-family);
        font-weight: 400;
        padding: 0 1rem;
        color: #fff;
        cursor: pointer;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }


}
