.intro {
    position: relative;
    overflow: hidden;
    flex: 1 auto;
    box-sizing: border-box;
    padding-top: 1px;
    background: black;
    color: white;
    display: flex;
    flex-direction: column;
    padding: 0rem 1rem 1rem 6rem;
    font-family: var(--font-family);
    flex: 2;
    
    justify-content: center;
    align-items: center;
}

.intro-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 7rem 9rem;
}


.intro__title span {
    background: var(--gradient-color);
}
.intro__title  {
    font-weight: 600;
    
}

.intro-content > h3 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 50px;
    width: 1590px;
    line-height: 95px;
    letter-spacing: -0.04em;
}
.intro-content > h2 {
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 50px;
    width: 1590px;
    line-height: 95px;
    letter-spacing: -0.04em;
}
.intro-content > p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 38px;
    line-height: 28px;
    margin: 1rem 0 4rem 0;
}

.wt {
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.wt-item {
    margin-right: 90px;
}
.wt-item__text {
    display: flex;
    align-items: center;
}
.wt-item__text > h3 {
    margin: 0 0.5rem;
}
.wt-icon {
    width: 40px;
    height: 64px;
    display: flex;
    align-items: center;
    cursor: pointer;
    object-fit: contain;
    justify-content: center;
}

.wt-h3 {
    font-weight: 530;
    font-size: 20px;
}
.wt-p {
    margin-top: 8px;
    margin-bottom: 8px;
    color: lightgrey;
    font-weight: 400;
    font-size: 19px;
}

.wt-link {
    color: dodgerblue;
    font-weight: 400;
    font-size: 19px;
}

.banner-tiles {
    justify-content: space-between; 
    height: 1600px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tiles {
    flex-direction: row;
    display: block;
}

.big-tile__img  {
    object-fit: contain;
    display: flex;
   
}
.big-tile__img > a > img {
    border-radius: 25px;
    width: 900px;
    height: 850px;
}

/* Media Query */
@media screen and (max-width: 1665px) {
    .intro-content {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        padding: 7rem 7rem;
    }
    .banner-tiles {
        justify-content: space-between; 
        height: 600px;
        width: 2400px;
        align-items: center;
        justify-content: center;
        flex: 2;
        
    }
    .intro-content > h3 {
        font-size: 50px;
        width: 700px;
        line-height: 95px;
        letter-spacing: -0.04em;
    }
    .intro-content > h2 {
        font-size: 50px;
        width: 700px;
        line-height: 95px;
        letter-spacing: -0.04em;
    }
    .intro-content > p {
        font-size: 29px;
        line-height: 28px;
        margin: 1rem 0 4rem 0;
    }
    .big-tile__img > a > img {
        border-radius: 25px;
        width: 550px;
        height: 550px;
    }
    
}
@media screen and (max-width: 1235px) {
    
    .banner-tiles {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 850px;
        margin-left: -12%;
    }
    
    .tiles {
        flex-direction: column;
        display: flex;
        
    }
    .big-tile__img > a > img {
        border-radius: 25px;
        height: 400px;
        width: 400px;
    }
    .intro-content > h3 {
        width: 100%;
        font-size: 3.1rem;
    }
    .intro-content > p {
        width: 100%;
        font-size: 1.6rem;
    }
    .intro__title span {
        background: var(--gradient-color);
    }
    .intro__title  {
        font-weight: 600;
        width: 100%;
        font-size: 1rem;
        line-height: 4.5rem;
    }
}
@media screen and (max-width: 888px) {
    .intro-content > h1 {
        font-size: 1rem;
        width: 100%;
    }
    .intro-content > h3 {
        font-size: 2.5rem;
        width: 100%;
    }
    .intro-title {
        font-size: 2rem;
        width: 100%;
    }
    .intro-content > h2 {
        font-size: 3rem;
        width: 100%;
    }
    .intro-content > p {
        width: 100%;
        font-size: 1.5rem;
    }
    .wt-h1 {
        font-size: 20px;
    }
    .wt-p {
        font-size: 20px;
    }
    .wt-link {
        font-size: 15px;
    }
    .banner-tiles {
        height: 1250px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .tiles {
        justify-content: space-between; 
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .big-tile__img  {
        object-fit: contain;
        display: flex;  
        margin-bottom: 1rem;
    }
    .big-tile__img > a > img {
        border-radius: 25px;
        height: 550px;
        width: 555px;
    }
    .wt-item {
        background: #000;
        padding: 2rem;
        border-radius: 25px;
        margin-bottom: 5rem;
        width:fit-content;
    }
}

@media screen and (max-width: 390px) {
    .intro {
        display: flex;
    }
    .trusted-content {
        padding: none;
    }
    .intro-content {
        align-items: center;
        justify-content: center;
        display: flex;
        margin-left: -40%;
    }
    .intro-content > h3 {
        font-size: 2rem;
        width: 230px;
        line-height: 50px;
        font-weight: 500;
    }
    .intro-content > h2 {
        font-size: 2.5rem;
        max-width: 190px;
        line-height: 50px;
    }
    .intro__title >  span {
        font-size: 1.5rem;
        max-width: 20px;
        line-height: 50px;
    }
    .intro-content > p {
        width: 200px;
        font-size: 1.5rem;
    }
    .wt {
        align-items: center;
        justify-content: center;
        display: flex;
    }
    .wt-item {
        background: #000;
        padding: 2rem;
        border-radius: 25px;
        margin-bottom: 5rem;
        margin-left: 20%;
        width: 100%;
    }
    .wt-h1 {
        font-size: 18px;
    }
    .wt-p {
        font-size: 1.2rem;
    }
    .wt-link {
        font-size: 1rem;
    }
    .banner {
        width: 100%;
        flex: 1;
    }
    .big-tile__img  {
        object-fit: contain;
        display: flex;  
    }
    .big-tile__img > a > img {
        border-radius: 25px;
        height: 450px;
        width: 450px;
    }
}