.quotes {
    display: flex;
    background: rgb(11, 11, 11);
    width: 100%;
    padding: 7% 0%;
    font-family: var(--font-family);
    flex-direction: column; 
    color: white;
}
.quotes > h1 {
    justify-content: center;
    align-items: center;
    text-align: center;
}
.quotes__text {
    display: flex;
    padding: 50px;
    flex-direction: column;
    width: 100%;
    justify-content: space-around;
    flex: 1;
}
.quote__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 2%;
    line-height: 50px;
}
.quote__container > h1 {
    display: flex;
   font-size: 30px;
    font-weight: 300;
}
.quote__container > p {
    display: flex;
   font-size: 20px;
   margin-top: 2%;
    font-weight: 300;
    color: rgb(163, 161, 161);
}
@media screen and (max-width: 1000px) {
    .quotes {
        display: flex;
        background: rgb(18, 18, 18);
        width: 100%;
        padding: 7% 0%;
        font-family: var(--font-family);
        flex-direction: column; 
        color: white;
    }
    .quotes > h1 {
        justify-content: center;
        align-items: center;
        font-size: xx-large;
        text-align: center;
    }
    .quotes__text {
        display: flex;
        padding: 50px;
        width: 100%;
        justify-content: space-around;
        flex-direction: column;
        flex: 1;
    }
    .quote__container {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 2%;
        line-height: 50px;
    }
    .quote__container > h1 {
        display: flex;
       font-size: 25px;
        font-weight: 300;
    }
    .quote__container > p {
        display: flex;
       font-size: medium;
       margin-top: 10%;
        font-weight: 300;
        color: rgb(163, 161, 161);
    }
}